<template>
    <div
        v-if="getMyLikes"
        style="display: grid; grid-template-columns: repeat(4, 25%); "
        class="ml-4"
    >
        <div
            v-if="(loading && !getVotedCoin)"
            style="grid-column: 2 / 4; display: flex; justify-content: center;"
        >
            <v-progress-circular
                :indeterminate="true"
                color="primary"
            />
        </div>

        <div
            v-if="getVotedCoin && !removing"
            style="grid-column: 2 / 4; display: flex; justify-content: center;"
        >
            <v-img
                :src="getMyLikes[getVotedCoin].image"
                contain
                style="'opacity: 1';  max-height: 80px; max-width: 80px;"
                class="cursos-pointer"
                @click="removeLike()"
            />
        </div>
        <div
            v-for="key in Object.keys(getMyLikes)"
            v-else-if="!loading"
            :key="key"
            class="mx-2 my-0 py-0"
            style=" display: flex; justify-content: center; flex-wrap: wrap; "
        >
            <img
                :class="'imageCoin' +(getMyLikes[key].available ? ' cursos-pointer' : '')"
                :src="getMyLikes[key].image"
                :style="' ' + (!getMyLikes[key].available ? ' opacity: 0.2' : 'opacity: 1')"
                @click="doLike(key)"
            >
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
    name: 'MyActiveCoins',
    props: {
        purposeId: {
            type: Number,
            default: 0,
            require: false
        }
    },
    data() {
        return {
            loading: false,
            removing: false
        }
    },
    mounted() {

    },
    computed: {
        ...mapGetters('statistics', ['getMyLikes']),
        getVotedCoin() {
            if (this.purposeId) {
                return Object.keys(this.getMyLikes).find(key => this.getMyLikes[key].purposes_cloud_id === this.purposeId)
            }
            return null
        }
    },
    methods: {

        doLike(coin) {
            if (this.purposeId) {
                if (this.getMyLikes[coin].available) {
                    this.loading = true
                    this.removing = false;
                    this.$emit('doLike', parseInt(coin))
                } else {
                    this.$store.dispatch('snackbar/error', this.$t('purposes.errorUsedCoin'))
                }
            }
        },
        removeLike() {
            this.loading = false;
            this.removing = true;
            this.$emit('removeLike', parseInt(this.getVotedCoin));
        }
    }
}
</script>

<style scoped>
.imageCoin {
  display: block;
  max-width: 60px;
  max-height: 60px;
  width: auto;
  height: auto;
}
</style>
